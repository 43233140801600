import React, { Component } from 'react';
import styles from './LeaderBoard.module.scss';

const img1 = require('assets/img/leaderboard-img1.jpeg');
const img2 = require('assets/img/leaderboard-img2.jpeg');
const img3 = require('assets/img/leaderboard-img3.jpeg');
const img4 = require('assets/img/leaderboard-img4.jpeg');
const img5 = require('assets/img/leaderboard-img5.jpeg');
const img6 = require('assets/img/leaderboard-img6.jpeg');
const img7 = require('assets/img/leaderboard-img7.jpeg');
const img8 = require('assets/img/leaderboard-img8.jpeg');
const img9 = require('assets/img/leaderboard-img9.jpeg');

class LeaderBoard extends Component {
	render() {
		return (
			<div className="main-section">
				<div className="page-section">
					<div className="container">
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<div className="cs-section-title" style={{ paddingBottom: "60px" }}>
								<div className="center" style={{ paddingBottom: "30px" }}>
									<h1>Weekly Contribution Spotlight</h1>
								</div>
								<div className="row">
									<div className="col-lg-3 col-md-3 col-sm-2 col-xs-12">
										Image
									</div>
									<div className="col-lg-5 col-md-5 col-sm-5 col-xs-12">
										<b>Pranav Nutalapati</b><br/>
										<img src={img1} height="30" alt="img1" />
										<img src={img2} height="30" alt="img2" />
										<img src={img3} height="30" alt="img3" />
										<img src={img4} height="30" alt="img4" />
										<img src={img5} height="30" alt="img5" />
										<img src={img6} height="30" alt="img6" />
										<img src={img7} height="30" alt="img7" />
										<img src={img8} height="30" alt="img8" />
										<img src={img9} height="30" alt="img9" />
									</div>
									<div className="col-lg-4 col-md-4 col-sm-5 col-xs-12 center">
										<h2>Contribution stats</h2>
										<div className="row">
											<div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
												<div style={{ color:"#3488bf", fontSize: "28px" }}>
													<b>42</b>
												</div>
												Uploads
											</div>
											<div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
												<div style={{ color:"#3488bf", fontSize: "28px" }}>
													<b>10</b>
												</div>
												Followers
											</div>
											<div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
												<div style={{ color:"#3488bf", fontSize: "28px" }}>
													<b>90</b>
												</div>
												Networks
											</div>
											<div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
												<div style={{ color:"#3488bf", fontSize: "28px" }}>
													<b>8</b>
												</div>
												Badges
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="cs-section-title" style={{ paddingBottom: "60px" }}>
								<div className="center" style={{ paddingBottom: "30px" }}>
									<h1>Weeky LeaderBoards</h1>
								</div>
								<div className="row">
									<div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
										<h4>Top Sharer</h4>
										<div className="row" style={{ padding: "10px 0px"}}>
											<div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
												Image
											</div>
											<div className="col-lg-9 col-md-9 col-sm-9 col-xs-9">
												<div className={styles.name}>Pranav Nutalapati</div>
												<div className={styles.achievement}>
													&nbsp;40 uploads this week&nbsp;
												</div>
											</div>
										</div>
										<div className="row" style={{ padding: "10px 0px"}}>
											<div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 center">
												2
											</div>
											<div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 center">
												Image
											</div>
											<div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
												<b style={{ color: "#3488bf" }}>Julie Low</b>
											</div>
										</div>
										<div className="row" style={{ padding: "10px 0px"}}>
											<div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 center">
												3
											</div>
											<div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 center">
												Image
											</div>
											<div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
												<b style={{ color: "#3488bf" }}>Logan Tan</b>
											</div>
										</div>
										<div className="row" style={{ padding: "10px 0px"}}>
											<div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 center">
												4
											</div>
											<div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 center">
												Image
											</div>
											<div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
												<b style={{ color: "#3488bf" }}>Logan Nutalapati</b>
											</div>
										</div>
										<div className="row" style={{ padding: "10px 0px"}}>
											<div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 center">
												5
											</div>
											<div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 center">
												Image
											</div>
											<div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
												<b style={{ color: "#3488bf" }}>Jim Kwik</b>
											</div>
										</div>
									</div>
									<div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
									<h4>Most Consistent sharer</h4>
										<div className="row" style={{ padding: "10px 0px"}}>
											<div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
												Image
											</div>
											<div className="col-lg-9 col-md-9 col-sm-9 col-xs-9">
												<div className={styles.name}>June Liew</div>
												<div className={styles.achievement}>
													&nbsp;20 uploads/week for 1 year&nbsp;
												</div>
											</div>
										</div>
										<div className="row" style={{ padding: "10px 0px"}}>
											<div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 center">
												2
											</div>
											<div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 center">
												Image
											</div>
											<div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
												<b style={{ color: "#3488bf" }}>Julie Low</b>
											</div>
										</div>
										<div className="row" style={{ padding: "10px 0px"}}>
											<div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 center">
												3
											</div>
											<div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 center">
												Image
											</div>
											<div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
												<b style={{ color: "#3488bf" }}>Logan Tan</b>
											</div>
										</div>
										<div className="row" style={{ padding: "10px 0px"}}>
											<div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 center">
												4
											</div>
											<div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 center">
												Image
											</div>
											<div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
												<b style={{ color: "#3488bf" }}>Logan Nutalapati</b>
											</div>
										</div>
										<div className="row" style={{ padding: "10px 0px"}}>
											<div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 center">
												5
											</div>
											<div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 center">
												Image
											</div>
											<div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
												<b style={{ color: "#3488bf" }}>Jim Kwik</b>
											</div>
										</div>
									</div>
									<div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
									<h4>Sharer with most downloads</h4>
										<div className="row" style={{ padding: "10px 0px"}}>
											<div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
												Image
											</div>
											<div className="col-lg-9 col-md-9 col-sm-9 col-xs-9">
												<div className={styles.name}>Pranav Nutalapati</div>
												<div className={styles.achievement}>
													&nbsp;300 downloads this week&nbsp;
												</div>
											</div>
										</div>
										<div className="row" style={{ padding: "10px 0px"}}>
											<div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 center">
												2
											</div>
											<div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 center">
												Image
											</div>
											<div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
												<b style={{ color: "#3488bf" }}>Julie Low</b>
											</div>
										</div>
										<div className="row" style={{ padding: "10px 0px"}}>
											<div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 center">
												3
											</div>
											<div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 center">
												Image
											</div>
											<div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
												<b style={{ color: "#3488bf" }}>Logan Tan</b>
											</div>
										</div>
										<div className="row" style={{ padding: "10px 0px"}}>
											<div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 center">
												4
											</div>
											<div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 center">
												Image
											</div>
											<div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
												<b style={{ color: "#3488bf" }}>Logan Nutalapati</b>
											</div>
										</div>
										<div className="row" style={{ padding: "10px 0px"}}>
											<div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 center">
												5
											</div>
											<div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 center">
												Image
											</div>
											<div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
												<b style={{ color: "#3488bf" }}>Jim Kwik</b>
											</div>
										</div>
									</div>
								</div>
							</div>
            </div>
          </div>
				</div>
			</div>
		);
	}
}

export default LeaderBoard;