import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { push } from 'connected-react-router';
// import Helmet from 'react-helmet';
import cx from 'classnames';
import PropTypes from 'prop-types';
import qs from 'query-string';
import Slider from 'react-slick';
import Partners from '../Partners/Partners';
import LeaderBoard from '../LeaderBoard/LeaderBoard';
import NavItem from '../NavItem/NavItem';
// import config from '../../config';
import { preview as previewResources } from 'redux/modules/resources';
import { show as showSignupModal } from 'redux/modules/modals/signup';
import { mapEntityToKeyword, mapValueToName } from 'utils/config';
import styles from './Home.module.scss';

const imgBackground = require('assets/img/home_background.jpg');
// const icons = ['icon-progress-one', 'icon-progress-two', 'icon-progress-full'];

class Home extends Component {
  static propTypes = {
    config: PropTypes.object,
    configByValue: PropTypes.object,
    user: PropTypes.object,
    users: PropTypes.object.isRequired,
    resources: PropTypes.object.isRequired,
    previewsByMainLevel: PropTypes.object.isRequired,
    showSignupModal: PropTypes.func.isRequired,
    pushState: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      search: '',
    };
  }

  componentDidMount() {
    this.props.previewResources({ mainLevel: 'primary' });
    this.props.previewResources({ mainLevel: 'secondary' });
    this.props.previewResources({ mainLevel: 'junior-college' });
    this.props.previewResources({ mainLevel: 'international-baccalaureate' });
  }

  handleFormSearch = event => {
    event.preventDefault();

    const { search } = this.state;

    this.props.pushState({
      pathname: `/${mapEntityToKeyword('resources')}/list`,
      search: `?${qs.stringify({ search: JSON.stringify(search) })}`,
    });
  };

  handleShowSignupModal = event => {
    event.preventDefault();
    this.props.showSignupModal();
  };

  shuffle = array => {
    const list = array.sort(() => Math.random() - 0.5);
    return list;
  }

  render() {
    // require the logo image both from client and server
    // const logoImage = require('./logo.png');
    // const { config: { levels, subjects }, users, resources } = this.props;
    const {
      configByValue = {},
      user,
      users,
      resources,
      previewsByMainLevel,
    } = this.props;
    const {
      /* mainLevels = undefined, */ subjects = undefined,
      levels = undefined,
    } = configByValue;
    const previews = 
      previewsByMainLevel.primary &&
      previewsByMainLevel.secondary &&
      previewsByMainLevel['junior-college'] &&
      previewsByMainLevel['international-baccalaureate'] &&
      this.shuffle(previewsByMainLevel['junior-college'].slice(0, 4)
                    .concat(previewsByMainLevel['international-baccalaureate'].slice(0, 4))
                    .concat(previewsByMainLevel.primary.slice(0, 4))
                    .concat(previewsByMainLevel.secondary.slice(0, 4))
      );

    return (
      <div className={styles.home}>
        <div
          className={cx('page-section', styles.banner)}
          style={{ backgroundImage: `url(${imgBackground})` }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="cs-column-text">
                  <h1 className={styles.tagline}>Empower Yourself</h1>
                  <h2 className={styles.subtag}>
                    Access quality notes, worksheets, and <br/>
                    practice papers from passionate sharers <br/>
                    in Singapore.
                  </h2>
                  {user && (
                    <NavItem
                      to={`/${mapEntityToKeyword('resources')}/add`}
                      className={styles.getStartedBox}
                    >
                      Get Started For Free
                    </NavItem>
                  )}
                  {!user && (
                    <div style={{ display: "inline" }}>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a tabIndex="0" onClick={this.handleShowSignupModal} className={styles.getStartedBox}>
                        Get Started For Free
                      </a>
                    </div>
                  )}
                  <Link
                    to={`/${mapEntityToKeyword('resources')}/`}
                    className={styles.inputBox}
                  >
                    Search Resources
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="page-section"
          style={{ background: '#f9fafa', paddingTop: '32px' }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="cs-section-title left">
                  <div className="row">
                    <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12" style={{ paddingTop: "20px" }}>
                      <div className={styles.title}>Resources for everyone</div>
                      <b>For primary, secondary, JC</b>
                    </div>
                    <NavLink
                      to={`/${mapEntityToKeyword('resources')}/`}
                      className={cx('pull-right col-lg-4 col-md-4 col-sm-12 col-xs-12', styles.viewAll)}
                    >
                      View All
                    </NavLink>
                  </div>
                </div>
              </div>
              <div className="page-content col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="row">
                  <Slider
                    dots
                    lazyLoad
                    arrows={false}
                    autoplay={false}
                    draggable={false}
                    slidesToShow={4}
                    slidesToScroll={4}
                    responsive={[
                      {
                        breakpoint: 479,
                        settings: {
                          slidesToShow: 1,
                          slidesToScroll: 1,
                        },
                      },
                      {
                        breakpoint: 767,
                        settings: {
                          slidesToShow: 2,
                          slidesToScroll: 2,
                        },
                      },
                      {
                        breakpoint: 1023,
                        settings: {
                          slidesToShow: 3,
                          slidesToScroll: 3,
                        },
                      },
                      {
                        breakpoint: 100000,
                        settings: {
                          slidesToShow: 4,
                          slidesToScroll: 4,
                        },
                      },
                    ]}
                  >
                    {previews &&
                      previews.map(preview => {
                        const resource = resources[preview._id];
                        const author =
                          resource &&
                          resource.author &&
                          users &&
                          users[resource.author];
                        return (
                          <div key={resource._id}>
                            <div
                              className="cs-courses courses-grid"
                              style={{ margin: '0 10px' }}
                            >
                              <div className="cs-media">
                                <figure>
                                  <Link
                                    to={`/${mapEntityToKeyword('resource')}/${
                                      resource.subject
                                    }/${resource.slug}`}
                                  >
                                    <div
                                      style={{
                                        backgroundImage: `url(${resource.avatar ||
                                          require('assets/img/resource.png')})`,
                                      }}
                                    />
                                  </Link>
                                </figure>
                              </div>
                              <div className="cs-text">
                                {/*
                                    <div className="cs-rating">
                                      <div className="cs-rating-star">
                                        <span className="rating-box" style={{ width: '100%' }}></span>
                                      </div>
                                    </div>
                                  */}
                                <span className="cs-caption">
                                  {mapValueToName(subjects, resource.subject)}
                                </span>
                                {resource.levels.map(level => (
                                  <span className="cs-caption" key={level}>
                                    {mapValueToName(levels, level)}
                                  </span>
                                ))}
                                <div className="cs-post-title">
                                  <h5>
                                    <Link
                                      to={`/${mapEntityToKeyword(
                                        'resource'
                                      )}/${resource.subject}/${
                                        resource.slug
                                      }`}
                                    >
                                      {resource.name}
                                    </Link>
                                  </h5>
                                  <div>
                                    <i className="icon-download" />
                                    {` ${resource.nDownloads} Downloads`}
                                  </div>
                                </div>
                                <div className="cs-post-meta">
                                  <span>
                                    By&nbsp;
                                    <Link
                                      to={`/users/${author && author._id}`}
                                      className="cs-color"
                                    >
                                      {author && author.name}
                                    </Link>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-section" style={{ margin: '30px 0' }}>
          <div className="container">
            <div className="row">
              <div className="cs-section-title center">
                <div className={styles.title}>Share what you know</div>
                <p className={styles.intro} style={{ paddingTop: '30px' }}>
                  Nam sagittis imperdiet turpis vitae sagittis. Donec ac facilisis sapien.
                  Interdum et malesuada fames ac ante ipsum primis in faucibus.
                  Sed vel consequat tortor.
                </p>
                {user && (
                  <NavItem
                    to={`/${mapEntityToKeyword('resources')}/add`}
                    className={styles.getStartedBox}
                  >
                    Get Started For Free
                  </NavItem>
                )}
                {!user && (
                  <div style={{ display: "inline" }}>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a tabIndex="0" onClick={this.handleShowSignupModal} className={styles.getStartedBox}>
                      Get Started For Free
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <LeaderBoard/>
        <div className="page-section" style={{ margin: '30px 0' }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="cs-section-title left">
                  <div className={cx('row', styles.title)}>
                    <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12" style={{ paddingTop: "30px" }}>
                      Press Coverage
                    </div>
                    <NavLink
                      to={`/${mapEntityToKeyword('sg-news')}`}
                      className={cx('pull-right col-lg-4 col-md-4 col-sm-12 col-xs-12', styles.viewAll)}
                    >
                      View All
                    </NavLink>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <h1>
                        Throwing Money At Singapore’s Problems Won’t Fix Them
                      </h1>
                      <p style={{ paddingTop: '10px' }}>
                        Publish on 16 Jan 2020
                        <b style={{ display: "inline", color: "#3488bf" }}>
                          {' '}on RICE media
                        </b>
                      </p>
                      <div>
                        "In the same way, when Singaporeans appear to clamour for more money with each
                        Budget, it’s perhaps not so much a desire for the thing itself but the changes it can effect...
                        [and] on an organisational level, maybe it’s more bandwidth for SmartGuppy’s servers so
                        that they can provide more material for needy families rather than a rosier number on their
                        yearly financial statements..."
                      </div>
                      <div style={{ textAlign: 'center', padding: '10px' }}>
                        <a
                          href="https://www.ricemedia.co/current-affairs-commentary-throwing-money-problems/"
                          className={styles.morePartners}
                        >
                          Read full article on RICE media
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <h1>
                        Throwing Money At Singapore’s Problems Won’t Fix Them
                      </h1>
                      <p style={{ paddingTop: '10px' }}>
                        Publish on 16 Jan 2020
                        <b style={{ display: "inline", color: "#3488bf" }}>
                          {' '}on RICE media
                        </b>
                      </p>
                      <div>
                        "In the same way, when Singaporeans appear to clamour for more money with each
                        Budget, it’s perhaps not so much a desire for the thing itself but the changes it can effect...
                        [and] on an organisational level, maybe it’s more bandwidth for SmartGuppy’s servers so
                        that they can provide more material for needy families rather than a rosier number on their
                        yearly financial statements..."
                      </div>
                      <div style={{ textAlign: 'center', padding: '10px' }}>
                        <a
                          href="https://www.ricemedia.co/current-affairs-commentary-throwing-money-problems/"
                          className={styles.morePartners}
                        >
                          Read full article on RICE media
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="center">
                  <Partners />
                  <div className="cs-section-title">
                    <Link
                      to={`/${mapEntityToKeyword('our-partners')}`}
                      className={styles.viewAll}
                    >
                      View All
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-section" style={{ background: '#f9fafa' }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="cs-column-text">
                  <div
                    className="call-actions cs-bgcolor"
                    style={{ marginBottom: '20px' }}
                  >
                    <div className="cell icon">
                      <i className="icon-mood" />
                    </div>
                    <div className="cell heading">
                      <h5>Help us with our fund-raising</h5>
                      <p>
                        We aim to empower underprivileged students and help them
                        succeed!
                      </p>
                    </div>
                    <div className="cell cell-btn">
                      <NavLink to="/donate" className="information-btn">
                        Donate Now
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  state => {
    const {
      entities: { users, resources },
      resources: { previewsByMainLevel },
    } = state;

    const user = state.auth.user && users[state.auth.user];

    return {
      config: state.config && state.config.data,
      configByValue: state.config && state.config.dataByValue,
      user,
      users,
      resources,
      previewsByMainLevel,
    };
  },
  { pushState: push, previewResources, showSignupModal }
)(Home);
